import React from 'react'

import './EnrolledCoursesList.css';

import { Box } from '@mui/material';
import { useUser } from '../../../../UserContext';
import { useNavigate } from 'react-router-dom';

import CourseCardItem from './CourseCardItem';

function getCoursesToShow(user, filter) {
	var courseKeys = [];

	if ((1 === filter || 2 === filter) && undefined !== user.classroom_offline_courses_enrolled) {
		courseKeys = [...courseKeys, ...user.classroom_offline_courses_enrolled]
	}

	if ((1 === filter || 3 === filter) && undefined !== user.classroom_online_courses_enrolled) {
		courseKeys = [...courseKeys, ...user.classroom_online_courses_enrolled]
	}

	if ((1 === filter || 4 === filter) && undefined !== user.ondemand_courses_enrolled) {
		courseKeys = [...courseKeys, ...user.ondemand_courses_enrolled]
	}

	return (courseKeys);
}

const EnrolledCoursesList = ({ filter, coursesData }) => {

	const { user } = useUser();

	let navigate = useNavigate();

	if (null === user) {
		navigate('/home');
	}

	return (
		<Box
			display='flex'
			flexDirection='row'
			flexWrap={'wrap'}
			alignItems='center'
			justifyContent='center'
			width='90%'
			gap
			className='enrolled-courses-list'>

			{
				getCoursesToShow(user, filter).map((key, index) => {
					return (
						<CourseCardItem
							key={index}
							index={index}
							courseData={coursesData[key.split('_')[0]]} />
					)
				})
			}

		</Box>
	)
}

export default EnrolledCoursesList