import React from 'react'

import './AppBarComponent.css';

import { AppBar, Box, Divider, IconButton, Stack, Typography } from '@mui/material'
import { Navbar } from '../../../../Components/NavBar/Navbar'

import { FaYoutube } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";

export const AppBarComponent = () => {

	return (
		<Box>
			<AppBar
				sx={{
					background: 'transparent',
					boxShadow: 'none !important',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center !important'
				}}>

				<Box
					className='header-top-wrapper'
					display={'flex'}
					justifyContent={'space-between'}
					flexDirection={'row'}
					width={'100%'}
					height={'25px'}>

					<Box
						className='header-top-container'
						display={'flex'}
						justifyContent={'space-between'}
						flexDirection={'row'}
						width={'100%'}>

						<Stack
							direction={'row'}
							alignItems={'center'}
							gap >
							<Typography
								variant='subtitle2'>
								(+91) 8830551355
							</Typography>
							<Divider
								sx={{
									borderWidth: '1px !important',
									borderColor: '#888 !important',
									height: '70%',
								}} />
							<Typography
								variant='subtitle2'>
								marshallingvoid@gmail.com
							</Typography>
						</Stack>

						<Stack
							direction={'row'}
							alignItems={'center'}
							gap >

							<IconButton>
								<FaYoutube
									className='social-media-icon youtube-icon' />
							</IconButton>

							<Divider
								sx={{
									borderWidth: '1px !important',
									borderColor: '#888 !important',
									height: '70%',
								}} />

							<IconButton>
								<FaInstagram
									className='social-media-icon instagram-icon' />
							</IconButton>

							<Divider
								sx={{
									borderWidth: '1px !important',
									borderColor: '#888 !important',
									height: '70%',
								}} />


							<IconButton>
								<FaLinkedinIn
									className='social-media-icon linkedin-icon' />
							</IconButton>

							<Divider
								sx={{
									borderWidth: '1px !important',
									borderColor: '#888 !important',
									height: '70%',
								}} />

							<IconButton>
								<FaWhatsapp
									className='social-media-icon whatsapp-icon' />
							</IconButton>

							<Divider
								sx={{
									borderWidth: '1px !important',
									borderColor: '#888 !important',
									height: '70%',
								}} />

							<IconButton>
								<FaFacebookF
									className='social-media-icon facebook-icon' />
							</IconButton>

						</Stack>

					</Box>

				</Box>

				<Navbar />

				{/* <NotificationBar /> */}

			</AppBar>
		</Box>
	)
}
