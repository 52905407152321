import React from 'react'
import './BannerSlider.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import 'swiper/css/autoplay';

import img01 from './../../../../Assests/images/img01.avif';
import img02 from './../../../../Assests/images/img02.avif';
import img03 from './../../../../Assests/images/img03.avif';
import img04 from './../../../../Assests/images/img04.avif';
// import img05 from './../../../Assests/images/img05.avif';
// import img06 from './../../../Assests/images/img06.avif';
import img07 from './../../../../Assests/images/img07.avif';
import img08 from './../../../../Assests/images/img08.avif';
// import img09 from './../../../../Assests/images/img09.avif';
import img10 from './../../../../Assests/images/img10.avif';
// import img11 from './../../../Assests/images/img11.avif';
import img12 from './../../../../Assests/images/img12.avif';
import img13 from './../../../../Assests/images/img13.avif';
// import img14 from './../../../Assests/images/img14.avif';
// import img15 from './../../../Assests/images/img15.avif';
// import img16 from './../../../Assests/images/img16.avif';
import img17 from './../../../../Assests/images/img17.avif';
// import img18 from './../../../Assests/images/img18.avif';

var images = [
	{ link: img01 },
	{ link: img02 },
	{ link: img03 },
	{ link: img04 },
	// { link: img05 },
	// { link: img06 },
	{ link: img07 },
	{ link: img08 },
	// { link: img09 },
	{ link: img10 },
	// { link: img11 },
	{ link: img12 },
	{ link: img13 },
	// { link: img14 },
	// { link: img15 },
	// { link: img16 },
	{ link: img17 },
	// { link: img18 },
]

export const BannerSlider = () => {

	// const handlemouseenter = () => {
	// 	if (Swiper.current) Swiper.current.autoplay.start();
	// };

	return (
		<div
			className='bannerslider'>
			<Swiper
				// direction='vertical'
				autoHeight={true}
				centeredSlides={true}
				loop={true}
				slidesPerView={'auto'}
				speed={2500}
				autoplay={
					{
						delay: 4000,
						disableOnInteraction: false
					}
				}
				coverflowEffect={{
					rotate: 0,
					stretch: 0,
					depth: 100,
					slideShadows: false,
					modifier: 1
				}}
				effect={'fade'}
				// pagination={{ clickable: true }}
				modules={[EffectCoverflow, Pagination, Autoplay]}
				className="swiper_container"
			>
				{
					images.map((image, index) => {
						return (
							<SwiperSlide
								key={index}
								onClick={() => {
								}}>
								<img
									className='slide_image'
									src={image.link} alt="slide_image" />
							</SwiperSlide>
						);
					})
				}
			</Swiper>
		</div>
	)
}
