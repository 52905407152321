import React, { useEffect, useState } from 'react'

import './CountdownTimer.css';
import { Box, Stack, Typography } from '@mui/material';

function getDoubleDigits(value) {
	if (10 > value)
		return ('0' + value)
	else
		return (value);
}

const CountdownTimer = ({ toDateTime, variant = 'h5', showLabel = true, className }) => {

	const [daysDifference, setDaysDifference] = useState(0);
	const [hoursDifference, setHoursDifference] = useState(0);
	const [minutesDifference, setMinutesDifference] = useState(0);
	const [secondsDifference, setSecondsDifference] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {

			if (toDateTime - new Date() > 0) {

				const diff = Math.abs(toDateTime - new Date());

				const msInSecond = 1000;
				const msInMinute = msInSecond * 60;
				const msInHour = msInMinute * 60;
				const msInDay = msInHour * 24;

				const days = Math.floor(diff / msInDay);
				const hours = Math.floor((diff % msInDay) / msInHour);
				const minutes = Math.floor((diff % msInHour) / msInMinute);
				const seconds = Math.floor((diff % msInMinute) / msInSecond);

				setDaysDifference(days);
				setHoursDifference(hours);
				setMinutesDifference(minutes);
				setSecondsDifference(seconds);
			}
			else {
				setDaysDifference(0);
				setHoursDifference(0);
				setMinutesDifference(0);
				setSecondsDifference(0);
			}

		}, 1000);

		return () => clearInterval(timer);
	}, [toDateTime]);

	return (
		<Box
			display={'flex'}
			flexDirection={'row'}
			justifyContent={'center'}
			alignItems={'flex-start'}
			width={'100%'}
			className='countdown-timer'
			gap>

			<Stack
				direction={'column'}
				alignItems={'center'}>
				<Typography
					color={'#ff0'}
					className={className}
					variant={variant}
					style={{
						filter: 'drop-shadow(0px 0px 1px #f509)'
					}}>
					{getDoubleDigits(daysDifference)}
				</Typography>
				{
					showLabel
					&&
					<Typography
						color={'#ff0'}
						variant='subtitle2'
						style={{
							filter: 'drop-shadow(0px 0px 1px #f509)'
						}}>
						Days
					</Typography>

				}
			</Stack>

			<Typography
				color={'#ff0'}
				className={className}
				variant={variant}
				style={{
					filter: 'drop-shadow(0px 0px 1px #f509)'
				}}>
				:
			</Typography>

			<Stack
				direction={'column'}
				alignItems={'center'}>
				<Typography
					color={'#ff0'}
					className={className}
					variant={variant}
					style={{
						filter: 'drop-shadow(0px 0px 1px #f509)'
					}}>
					{getDoubleDigits(hoursDifference)}
				</Typography>
				{
					showLabel
					&&
					<Typography
						color={'#ff0'}
						variant='subtitle2'
						style={{
							filter: 'drop-shadow(0px 0px 1px #f509)'
						}}>
						Hours
					</Typography>
				}
			</Stack>

			<Typography
				color={'#ff0'}
				className={className}
				variant={variant}
				style={{
					filter: 'drop-shadow(0px 0px 1px #f509)'
				}}>
				:
			</Typography>

			<Stack
				direction={'column'}
				alignItems={'center'}>
				<Typography
					color={'#ff0'}
					className={className}
					variant={variant}
					style={{
						filter: 'drop-shadow(0px 0px 1px #f509)'
					}}>
					{getDoubleDigits(minutesDifference)}
				</Typography>
				{
					showLabel
					&&
					<Typography
						color={'#ff0'}
						variant='subtitle2'
						style={{
							filter: 'drop-shadow(0px 0px 1px #f509)'
						}}>
						Minutes
					</Typography>
				}
			</Stack>

			<Typography
				color={'#ff0'}
				className={className}
				variant={variant}
				style={{
					filter: 'drop-shadow(0px 0px 1px #f509)'
				}}>
				:
			</Typography>

			<Stack
				direction={'column'}
				alignItems={'center'}>
				<Typography
					color={'#ff0'}
					className={className}
					variant={variant}
					style={{
						filter: 'drop-shadow(0px 0px 1px #f509)'
					}}>
					{getDoubleDigits(secondsDifference)}
				</Typography>
				{
					showLabel
					&&
					<Typography
						color={'#ff0'}
						variant='subtitle2'
						style={{
							filter: 'drop-shadow(0px 0px 1px #f509)'
						}}>
						Seconds
					</Typography>
				}
			</Stack>
		</Box>
	)
}

export default CountdownTimer