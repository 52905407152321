import React, { useState } from 'react'

import './Profile.css';

import { Box, Divider, IconButton, Snackbar, Stack, Typography } from '@mui/material'
import { useUser } from '../../../../UserContext';

import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import copy from "copy-to-clipboard";

const Profile = () => {

	const { user } = useUser();

	const [snackbarOpen, setSnackbarOpen] = useState(false);

	const handleCopyToClipboard = () => {
		var isCopied = copy('MV_' + user.mv_id + '_' + user.first_name + '_' + user.last_name);

		if (isCopied) {
			setSnackbarOpen(true);
		}
	}

	const handleSnackbarClose = () => {
		setSnackbarOpen(false);
	}

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='space-evenly'
			width='100%'
			height={'100%'}
			className='profile-wrapper'>

			<Snackbar
				open={snackbarOpen}
				onClose={handleSnackbarClose}
				message="MVID Copied Successfully"
				key={'top left'}
			/>

			<Box
				display='flex'
				flexDirection='column'
				alignItems='flex-start'
				justifyContent='space-evenly'
				width='100%'
				className='profile-container'
				gap={'10px'}>

				<Typography
					variant='h4'
					sx={{
						padding: '10px'
					}}>
					Profile
				</Typography>

				<Divider
					sx={{
						borderColor: '#888',
						borderWidth: '1px',
						width: '100%'
					}} />

				<Box
					display='flex'
					flexDirection='column'
					alignItems='flex-start'
					justifyContent='space-evenly'
					width='100%'
					className='profile-user-info'
					padding={'10px'}
					borderRadius={'20px'}
					gap>
					<Stack
						direction={'row'}
						alignItems={'center'}
						gap>
						<Typography
							className='lecture_id'
							variant='h5'>
							MV ID:
						</Typography>
						{/* 
						<Typography
							variant='h5'>
							{user.mv_id}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						alignItems={'center'}
						gap>
						<Typography
							className='lecture_id'
							variant='h5'
							sx={{
								fontWeight: '600 !important'
							}}>
							Lecture ID:
						</Typography> */}

						<Typography
							variant='h5'
							className='lecture_id'
							sx={{
								fontWeight: '600 !important'
							}}>
							MV_{user.mv_id}_{user.first_name}_{user.last_name}
						</Typography>
						<IconButton
							sx={{
								cursor: 'pointer'
							}}
							onClick={handleCopyToClipboard}
							className='lecture_id'>
							<ContentCopyRoundedIcon />
						</IconButton>
					</Stack>

					<Stack
						direction={'row'}
						gap>
						<Typography
							variant='h5'>
							SignedUp On:
						</Typography>

						<Typography
							variant='h5'>
							{new Date(user.signed_up).toDateString()} {new Date(user.signed_up).getHours()}:{new Date(user.signed_up).getMinutes()}:{new Date(user.signed_up).getSeconds()}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						gap>
						<Typography
							variant='h5'>
							First Name:
						</Typography>
						<Typography
							variant='h5'>
							{user.first_name}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						gap>
						<Typography
							variant='h5'>
							Last Name:
						</Typography>
						<Typography
							variant='h5'>
							{user.last_name}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						gap>
						<Typography
							variant='h5'>
							User Name:
						</Typography>
						<Typography
							variant='h5'>
							{user.user_name}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						gap>
						<Typography
							variant='h5'>
							Mail ID:
						</Typography>
						<Typography
							variant='h5'>
							{user.mailid}
						</Typography>
					</Stack>

				</Box>
			</Box>
		</Box>
	)
}

export default Profile