import React, { useEffect, useState } from 'react'

import './ContentDetailsContainer.css';
import Navbar from '../Navbar/Navbar';
import { getDownloadURL, ref } from 'firebase/storage';

import { storage } from '../../../../../../Components/Common/firebase';

import { AboutCourse } from '../AboutCourse/AboutCourse';
import { CourseContent } from '../CourseContent/CourseContent';
import { CourseCertificate } from '../CourseCertificate/CourseCertificate';

export const ContentDetailsContainer = ({ courseData }) => {

	const [courseCertificateURL, setCourseCertificateURL] = useState('');

	useEffect(() => {

		async function fetchCertificateURL() {
			getDownloadURL(ref(storage, 'coursePosters/' + courseData.certificateImageName))
				.then((URL) => {
					setCourseCertificateURL(URL);
				})
		}

		fetchCertificateURL();

	}, [courseData]);

	//console.log(courseData);

	return (
		<div
			className='content-details-container'>

			<Navbar />

			<AboutCourse
				id={'AboutCourse'}
				courseData={courseData} />

			<CourseContent
				id={'CourseContent'}
				contentDownloadLink={courseData.contentDownloadLink}
				courseContent={courseData.content} />

			<CourseCertificate
				certificate={courseCertificateURL} />
		</div>
	)
}
