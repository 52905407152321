import React from 'react'

import './CourseDetailsHeader.css';

import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import LaptopMacRoundedIcon from '@mui/icons-material/LaptopMacRounded';
import LanguageIcon from '@mui/icons-material/Language';
import { Button, Divider, IconButton, Rating, Stack, Typography } from '@mui/material';

const CourseDetailsHeader = ({ courseData }) => {
	return (
		<Stack
			className='course-header-info-wrapper'
			direction={'column'}
			alignItems={'flex-start'}
			justifyContent={'flex-start'}
			width={'100%'}
			padding={'50px 0px'}>

			<Stack
				className='course-header-info-container'
				direction={'column'}
				alignItems={'flex-start'}
				justifyContent={'flex-start'}
				width={'max-content'}
				gap={'20px'}>

				<Typography
					className='course-title'
					color={'#fff'}
					variant='h3'>
					{courseData.name}
				</Typography>

				<Stack
					direction={'row'}
					alignItems={'center'}
					justifyContent={'flex-start'}
					width={'100%'}
					gap={'10px'}>

					<Stack
						direction={'row'}
						alignItems={'center'}
						justifyContent={'flex-start'}
						gap>

						<LanguageIcon
							sx={{
								color: '#fff !important'
							}}
							className='data-container-icon' />

						<Typography
							className='info-element-title'
							color={'#fff'}
							variant='subtitle1'>
							Marathi
						</Typography>
					</Stack>

					<Divider
						color='#888'
						sx={{
							borderWidth: '1px',
							height: 'auto',
							alignSelf: 'stretch',
						}} />

					<Stack
						direction={'row'}
						alignItems={'center'}
						justifyContent={'flex-start'}
						gap>
						<LaptopMacRoundedIcon
							sx={{
								color: '#fff !important'
							}}
							className='data-container-icon' />
						<Typography
							className='info-element-title'
							color={'#fff'}
							variant='subtitle1'>
							Online (ZOOM)
						</Typography>
					</Stack>

					<Divider
						color='#888'
						sx={{
							borderWidth: '1px',
							height: 'auto',
							alignSelf: 'stretch',
						}} />

					<Stack
						direction={'row'}
						alignItems={'center'}
						justifyContent={'flex-start'}
						gap>

						<VideoCameraFrontOutlinedIcon
							sx={{
								color: '#fff !important'
							}}
							className='data-container-icon' />

						<Typography
							className='info-element-title'
							color={'#fff'}
							variant='subtitle1'>
							Live
						</Typography>

					</Stack>
				</Stack >

				<Rating
					value={'5'}
					size='large'
					readOnly
				/>

				{
					courseData.freeLectureLink
					&&
					<Stack
						display={'flex'}
						flexDirection={'column'}
						alignItems={'center'}
						justifyItems={'center'}
						gap={'10px'}>
						<Typography
							style={{
								cursor: 'pointer'
							}}
							variant='h4'
							color={'#0ff !important'}
						>
							Click to register for Free Lectures
						</Typography>

						<IconButton
							className='card-online-button'
							sx={{
								width: 'max-content'
							}}
							onClick={() => {
								window.open(courseData.freeLectureLink, "_blank")
							}}
						>
							Register here
						</IconButton>
					</Stack >
				}
			</Stack >
		</Stack >
	)
}

export default CourseDetailsHeader