import { Alert, Box, Button, Snackbar } from '@mui/material'
import React, { useState } from 'react'
import CustomTextField from '../CustomTextField/CustomTextField'
import { doc, setDoc } from 'firebase/firestore';
import { firestore } from '../../../../../../Components/Common/firebase';
import { useUser } from '../../../../../../UserContext';

const SocialProfile = () => {

	const { user } = useUser();
	const [open, setOpen] = useState(false);

	const [websiteLink, setWebsiteLink] = useState(user.websiteLink || '');
	const [githubLink, setGithubLink] = useState(user.githubLink || '');
	const [linkedinLink, setLinkedinLink] = useState(user.linkedinLink || '');
	const [facebookLink, setFacebookLink] = useState(user.facebookLink || '');
	const [twitterLink, setTwitterLink] = useState(user.twitterLink || '');

	const handleClick = () => {

		user.websiteLink = websiteLink;
		user.githubLink = githubLink;
		user.linkedinLink = linkedinLink;
		user.facebookLink = facebookLink;
		user.twitterLink = twitterLink;

		setDoc(doc(firestore, 'Users/' + user.uid), user, { merge: true })
			.then(() => {
				setOpen(true);
			})
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const handleWeblinkChange = (e) => {
		setWebsiteLink(e.target.value);
	}

	const handleGithublinkChange = (e) => {
		setGithubLink(e.target.value);
	}

	const handleLinkedinlinkChange = (e) => {
		setLinkedinLink(e.target.value);
	}

	const handleFacebooklinkChange = (e) => {
		setFacebookLink(e.target.value);
	}

	const handleTwitterlinkChange = (e) => {
		setTwitterLink(e.target.value);
	}

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='space-evenly'
			width='100%'
			height={'100%'}
			className='social-profile-wrapper'>
			<Box
				display='flex'
				flexDirection='column'
				alignItems='flex-start'
				justifyContent='space-evenly'
				width='100%'
				height={'100%'}
				padding={'10px 20px'}
				boxSizing={'border-box'}
				className='social-profile-container'>

				<CustomTextField
					width="100%"
					type='text'
					id="standard-basic"
					label="Website"
					variant="standard"
					value={websiteLink}
					onChange={handleWeblinkChange} />

				<CustomTextField
					width="100%"
					type='text'
					id="standard-basic"
					label="Github"
					variant="standard"
					value={githubLink}
					onChange={handleGithublinkChange} />

				<CustomTextField
					width="100%"
					type='text'
					id="standard-basic"
					label="Linkedin"
					variant="standard"
					value={linkedinLink}
					onChange={handleLinkedinlinkChange} />

				<CustomTextField
					width="100%"
					type='text'
					id="standard-basic"
					label="Facebook"
					variant="standard"
					value={facebookLink}
					onChange={handleFacebooklinkChange} />

				<CustomTextField
					width="100%"
					type='text'
					id="standard-basic"
					label="X (Twitter)"
					variant="standard"
					value={twitterLink}
					onChange={handleTwitterlinkChange} />

				<Button
					onClick={handleClick}
					sx={{
						margin: '10px 0px'
					}}
					variant='contained'>
					Save Profile
				</Button>
			</Box>

			<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
				<Alert
					onClose={handleClose}
					severity="success"
					variant="filled"
					sx={{ width: '100%' }}
				>
					Profile Saved Successful
				</Alert>
			</Snackbar>
		</Box>
	)
}

export default SocialProfile