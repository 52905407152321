import React from 'react'

import './UserProfileCard.css';

import { Avatar, Card, CardContent, CardHeader, Typography } from '@mui/material'
import { useUser } from '../../../../UserContext';

export const UserProfileCard = () => {

	const { user } = useUser();

	return (
		<Card
			className='user-profile-card'
		>
			<CardHeader
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'flex-end !important',
					alignItems: "center",
					flexDirection: 'column !important',
					gap: '10px',
					padding: '20px 0px 0px 0px !important'
				}}
				avatar={
					<Avatar
						src={user.avatar_picture_url}
						className='user-profile-card-avatar'
					/>
				}>

			</CardHeader>

			<CardContent
				className='user-profile-card-content'
				sx={{
					padding: '5px !important'
				}}
			>

				<Typography
					variant='h4'
					color={'#fff'}
					textAlign={'center'}>
					{user.user_name}
				</Typography>

				<Typography
					variant='h5'
					color={'#aaa'}
					textAlign={'center'}>
					{user.designation}
				</Typography>

			</CardContent>
		</Card >
	)
}
