import { useEffect, useState } from 'react';

import './App.css';

import ReactGA from './ga';
import User from './Pages/User/User';
import Profile from './Pages/User/Components/Profile/Profile';
import Settings from './Pages/User/Components/Settings/Settings';
import Dashboard from './Pages/User/Components/Dashboard/Dashboard';
import CourseBatch from './Pages/User/Components/CourseBatch/CourseBatch';
import CourseDetailsPage from './Pages/CourseDetailesPage/CourseDetailsPage';
import EnrolledCourses from './Pages/User/Components/EnrolledCourses/EnrolledCourses';
import EnrolledCoursesList from './Pages/User/Components/EnrolledCourses/EnrolledCoursesList';
import ParticleBackground from './Components/ParticleBackground/ParticleBackground';

import { firestore } from './Components/Common/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

import { Footer } from './Components/Common/Footer';
import { MainPage } from './Pages/MainPage/MainPage';
import { UserProvider } from './UserContext';
import { LoadingPage } from './Components/Common/LoadingPage';
import { CoursePage } from './Pages/AllCoursesPage/CoursePage';
import { AboutUsPage } from './Components/AboutUsPage/AboutUsPage';
import { RegistrationFormPage } from './Pages/RegisterationFormPage/RegistrationFormPage';
import { AppBarComponent } from './Pages/MainPage/Components/AppBarComponent/AppBarComponent';

function usePageViews() {
	let location = useLocation();
	useEffect(() => {
		ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
	}, [location]);
}

function RouterListener() {
	usePageViews();
	return (null);
}

function App() {

	const [courseKeys, setCourseKeys] = useState([]);
	const [coursesData, setCoursesData] = useState(null);

	useEffect(() => {

		getDocs(collection(firestore, 'Courses'))
			.then((allDocs) => {
				setCourseKeys([]);
				setCoursesData([]);
				allDocs.forEach((doc) => {
					setCourseKeys(data => {
						return (
							[
								...data,
								doc.id
							]
						)
					})

					setCoursesData(data => {
						return (
							{
								...data,
								[doc.id]: (doc.data()[doc.data().currentBatch])
							}
						)
					})
				})
			})
	}, []);

	if (null === coursesData) {
		return (
			<LoadingPage />
		)
	}

	return (
		<BrowserRouter>
			<RouterListener />
			<UserProvider>

				<GoogleOAuthProvider
					clientId='851777582489-unsfve0umc180bnspc985kdborn51vmc.apps.googleusercontent.com'>
					<AppBarComponent
					/>
				</GoogleOAuthProvider>

				<Routes>
					<Route path='/' element={<MainPage />} />
					<Route path='/register-to-session' element={<RegistrationFormPage />} />
					<Route path='courses' element={<CoursePage />} />
					{
						courseKeys.map((key, index) => {
							return (
								<Route
									key={index}
									path={'course+details/' + key}
									element={
										<CourseDetailsPage
											courseData={coursesData[key]} />
									} />
							)
						})
					}
					<Route path='about-us' element={<AboutUsPage />} />

					<Route path='user' element={<User />} >
						<Route
							path='dashboard'
							element={<Dashboard />} />
						<Route
							path='profile'
							element={<Profile />}
						/>
						<Route
							path='enrolled-courses'
							element={<EnrolledCourses coursesData={coursesData} />} >
							<Route
								path='all-courses'
								element={
									<EnrolledCoursesList
										filter={1}
										coursesData={coursesData} />
								} />
							<Route
								path='offline-courses'
								element={
									<EnrolledCoursesList
										filter={2}
										coursesData={coursesData} />
								} />
							<Route
								path='online-courses'
								element={
									<EnrolledCoursesList
										filter={3}
										coursesData={coursesData} />
								} />
							<Route
								path='ondemand-courses'
								element={
									<EnrolledCoursesList
										filter={4}
										coursesData={coursesData} />
								} />
						</Route>
						<Route
							path='settings'
							element={<Settings />}
						/>

					</Route>
					{
						Object.keys(coursesData).map((key, index) => {
							return (
								<Route
									key={index}
									path={'user/' + key}
									element={
										<CourseBatch
											batch={coursesData[key]} />
									} />
							)
						})
					}
				</Routes>
			</UserProvider>
			<Footer />
			<ParticleBackground />

		</BrowserRouter >
	);
}

export default App;
