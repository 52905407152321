import React, { useEffect, useState } from 'react'

import './CourseCard.css';

import Card from '@mui/material/Card';
import { Box, Button, CardMedia, Stack, Typography } from '@mui/material';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../../Components/Common/firebase';
import { Link, useNavigate } from 'react-router-dom';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import PersonAddDisabledRoundedIcon from '@mui/icons-material/PersonAddDisabledRounded';
import CountdownTimer from '../../../../Components/CountdownTimer/CountdownTimer';

const days = [
	'Mon',
	'Tue',
	'Wed',
	'Thur',
	'Fri',
	'Sat',
	'Sun'
];

export const CourseCard = ({ course }) => {

	const firstDay = (new Date(course.startDate).getDay() === 0 ? 6 : new Date(course.startDate).getDay() - 1);

	const [posterURL, setPosterURL] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		getDownloadURL(ref(storage, 'coursePosters/' + course.posterWideName))
			.then((URL) => {
				setPosterURL(URL);
			})
	}, [course.posterWideName]);

	return (
		// <Link
		// 	className='course-link'
		// 	to={'/course+details/' + course.key}
		// 	style={{
		// 		textDecoration: 'none'
		// 	}}>
		<Box
			display={'flex'}
			flexDirection={'row'}
			margin={'auto'}
			flexWrap={'wrap'}
			alignItems={'flex-start'}
			justifyContent={'center'}
			height={'400px'}
			width={'247px'}
			sx={{
				cursor: 'pointer'
			}}
			className='course-card-wrapper'
			onClick={() => {
				navigate('/course+details/' + course.key)
			}}>
			<Card
				className='course-card'
				component={'div'}>
				<CardMedia
					sx={{
						// height: '140px',
						width: '100%',
						aspectRatio: '16/9'
					}}
					title='Course Name'
					image={posterURL}
					component={'img'}
				/>

				<Stack
					direction={'column'}
					className='course-card-info-container'
					alignItems={'center'}>

					<Typography
						textAlign={'center'}
						variant='h1'>
						{course.name}
					</Typography>

					{
						course.courseIsAdmissionsOpen
						&&
						<div
							className='course-card-discount'>
							<Typography
								variant='h6'>
								{course.discount}% OFF
							</Typography>
						</div>
					}

					<Stack
						direction={'row'}
						alignItems={'center'}
						justifyContent={'space-evenly'}
						className='month-day-container'>
						<Typography
							variant='h4'
							color={'#fa0 !important'}
							sx={{
								filter: ''
							}}>
							₹{course.fees * (100 - course.discount) / 100}
						</Typography>
						<Typography
							variant='h5'
							color={'#888 !important'}
							sx={{
								textDecoration: 'line-through'
							}}>
							₹{course.fees}
						</Typography>
					</Stack>

					<Typography
						variant='h6'>
						{new Date(course.fromTime).getHours() <= 12 ? new Date(course.fromTime).getHours() : new Date(course.fromTime).getHours() % 12}
						:
						{new Date(course.fromTime).getMinutes() < 10 ? new Date(course.fromTime).getMinutes() + '0' : new Date(course.fromTime).getMinutes()} {new Date(course.fromTime).getHours() < 12 ? 'AM' : 'PM'} to {new Date(course.toTime).getHours() <= 12 ? new Date(course.toTime).getHours() : new Date(course.toTime).getHours() % 12}
						:
						{new Date(course.toTime).getMinutes() < 10 ? new Date(course.toTime).getMinutes() + '0' : new Date(course.toTime).getMinutes()} {new Date(course.toTime).getHours() < 12 ? 'AM' : 'PM'}
					</Typography>


					<Stack
						direction={'row'}
						alignItems={'flex-start'}
						className='month-day-container'>
						<Typography
							variant='h6'>
							{course.nrMonths} Months
						</Typography>
						<Typography
							variant='h6'>
							{days[firstDay]} - {days[course.nrDays - 1 + firstDay]}
						</Typography>
					</Stack>

					<Button
						variant='contained'
						sx={{
							padding: '2px 5px',
							width: 'fit-content',
							backgroundColor: (course.courseIsAdmissionsOpen ? '#ff5500aa' : 'transparent')
						}
						} >
						{
							course.courseIsAdmissionsOpen
								?
								<CountdownTimer
									className={'admission-open'}
									variant={'h6'}
									showLabel={false}
									toDateTime={new Date(course.admissionTillDate)}
								/>
								:
								<Typography
									variant='subtitle1'
									padding={'1px'}
									className='admission-closed'>
									Explore
								</Typography>
						}
					</Button>

				</Stack>

			</Card>
		</Box >
	)
}
