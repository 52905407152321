import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../Components/Common/firebase";
import axios from 'axios'

export function handlePaymentSuccess(response, order_id, user, setUser, courseData) {

	if (undefined === order_id)
		order_id = 'NONE';

	var data;

	if (undefined === user.classroom_online_courses_enrolled) {
		data = {
			classroom_online_courses_enrolled: [courseData.batch],
			[courseData.batch]: {
				hasAccess: true,
				payment_id: response.razorpay_payment_id,
				order_id: order_id,
				enrolledTime: new Date().toString(),
				paidOneTime: true
			}
		}
	} else {
		if (-1 === user.classroom_online_courses_enrolled.indexOf(courseData.batch)) {
			data = {
				classroom_online_courses_enrolled: [...user.classroom_online_courses_enrolled, courseData.batch],
				[courseData.batch]: {
					hasAccess: true,
					payment_id: response.razorpay_payment_id,
					order_id: order_id,
					enrolledTime: new Date().toString(),
					paidOneTime: true
				}
			}
		}
	}

	setUser((user) => {
		return (
			{
				...user,
				...data
			}
		);
	});
	sessionStorage.setItem('user', JSON.stringify({
		...user,
		...data
	}));

	setDoc(doc(firestore, 'Users/' + user.uid), data, { merge: true });
	// alert(`Payment Successful. Payment ID: ${response.razorpay_payment_id} & ${order_id}`)
}

export function handlePaymentSuccessInstallment01(response, order_id, user, setUser, courseData) {

	if (undefined === order_id)
		order_id = 'NONE';

	var data;

	let endDate = new Date(courseData.admissionTillDate);
	endDate.setMonth(endDate.getMonth() + 1);

	let extensionDate = new Date(endDate);
	extensionDate.setDate(extensionDate.getDate() + 5);

	if (undefined === user.classroom_online_courses_enrolled) {
		data = {
			classroom_online_courses_enrolled: [courseData.batch],
			[courseData.batch]: {
				hasAccess: true,
				payment_id_installment_01: response.razorpay_payment_id,
				order_id_installment_01: order_id,
				enrolledTime_installment_01: new Date().toString(),
				paidOneTime: false,
				paidInstallment01: true,
				paidInstallment02: false,
				installment02_deadline: endDate.toString(),
				installment02_extended_deadline: extensionDate.toString()
			}
		}
	} else {
		if (-1 === user.classroom_online_courses_enrolled.indexOf(courseData.batch)) {
			data = {
				classroom_online_courses_enrolled: [...user.classroom_online_courses_enrolled, courseData.batch],
				[courseData.batch]: {
					hasAccess: true,
					payment_id_installment_01: response.razorpay_payment_id,
					order_id_installment_01: order_id,
					enrolledTime_installment_01: new Date().toString(),
					paidOneTime: false,
					paidInstallment01: true,
					paidInstallment02: false,
					installment02_deadline: endDate.toString(),
					installment02_extended_deadline: extensionDate.toString()
				}
			}
		}
	}

	setUser((user) => {
		return (
			{
				...user,
				...data
			}
		);
	});
	sessionStorage.setItem('user', JSON.stringify({
		...user,
		...data
	}));

	setDoc(doc(firestore, 'Users/' + user.uid), data, { merge: true });
	// alert(`Payment of Installment 01 Successful. Payment ID: ${response.razorpay_payment_id} & ${order_id}`)
}


export function handlePaymentSuccessInstallment02(response, order_id, user, setUser, courseData) {

	if (undefined === order_id)
		order_id = 'NONE';

	var data = {
		[courseData.batch]: {
			hasAccess: true,
			payment_id_installment_02: response.razorpay_payment_id,
			order_id_installment_02: order_id,
			enrolledTime_installment_02: new Date().toString(),
			paidOneTime: false,
			paidInstallment01: true,
			paidInstallment02: true,
		}
	}

	// let endDate = new Date(courseData.admissionTillDate);
	// endDate.setMonth(endDate.getMonth() + 1);

	// if (undefined === user.classroom_online_courses_enrolled) {
	// 	data = {
	// 		classroom_online_courses_enrolled: [courseData.batch],
	// 		[courseData.batch]: {
	// 			hasAccess: true,
	// 			payment_id_installment_02: response.razorpay_payment_id,
	// 			order_id_installment_02: order_id,
	// 			enrolledTime_installment_02: new Date().toString(),
	// 			paidOneTime: false,
	// 			paidInstallment01: true,
	// 			paidInstallment02: true,
	// 			installment02_deadline: endDate.toString()
	// 		}
	// 	}
	// } else {
	// 	if (-1 === user.classroom_online_courses_enrolled.indexOf(courseData.batch)) {
	// 		data = {
	// 			classroom_online_courses_enrolled: [...user.classroom_online_courses_enrolled, courseData.batch],
	// 			[courseData.batch]: {
	// 				hasAccess: true,
	// 				payment_id_installment_02: response.razorpay_payment_id,
	// 				order_id_installment_02: order_id,
	// 				enrolledTime_installment_02: new Date().toString(),
	// 				paidOneTime: false,
	// 				paidInstallment01: true,
	// 				paidInstallment02: true,
	// 				installment02_deadline: endDate.toString()
	// 			}
	// 		}
	// 	}
	// }

	setUser((user) => {
		return (
			{
				...user,
				...data
			}
		);
	});
	sessionStorage.setItem('user', JSON.stringify({
		...user,
		...data
	}));

	setDoc(doc(firestore, 'Users/' + user.uid), data, { merge: true });
	alert(`Payment of Installment 01 Successful. Payment ID: ${response.razorpay_payment_id} & ${order_id}`)
}


export async function handlePayment(fees, oneTimePayment, firstPayment, user, setUser, courseData) {

	const orderUrl = 'https://createorder-ge6xbfeo6q-uc.a.run.app';
	// const orderUrl = 'https://createordertest-ge6xbfeo6q-uc.a.run.app';
	// const orderUrl = 'http://localhost:5000/web-marshalling-void/us-central1/createOrder';
	const response = await axios.post(orderUrl, {
		data: {
			amount: fees,
			currency: 'INR',
			notes: {
				address: 'Class Address',
			},
		}
	});

	const { id: order_id, amount: order_amount } = response.data.result;
	// const user = JSON.parse(sessionStorage.getItem('user'));

	const options = {
		key: "rzp_live_hvtu2N3ct95A8c",
		// key: "rzp_test_FTWu0QQmTMYmoL",
		amount: order_amount,
		currency: response.data.currency,
		name: 'Marshalling Void',
		description: 'Test Transaction',
		id: order_id,
		handler: function (response) {
			// console.log(response);
			if (oneTimePayment) {
				handlePaymentSuccess(response, order_id, user, setUser, courseData);
			}
			else if (firstPayment) {
				// console.log("INSTALLMENT 01")
				handlePaymentSuccessInstallment01(response, order_id, user, setUser, courseData);
			}
			else {
				handlePaymentSuccessInstallment02(response, order_id, user, setUser, courseData);
			}
		},
		prefill: {
			name: (user.given_name + " " + user.family_name),
			email: user.email,
		},
		theme: {
			color: '#0077ff',
		}
	};

	const rzp1 = new window.Razorpay(options);
	rzp1.open();
}


