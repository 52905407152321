import React, { useState } from 'react'

import './LecturesContainer.css';

import { Box } from '@mui/material'

import LectureItem from './LectureItem';
import LectureVideoPlayer from '../../LectureVideoPlayer'
import YouTubeLecturePlayer from '../../YouTubeLecturePlayer'
import LectureListContainer from '../LecturesListContainer/LectureListContainer';

const LecturesContainer = ({ batch }) => {

	const [lectureData, setLectureData] = useState(null);

	return (
		<Box
			display='flex'
			flexDirection='row'
			alignItems={'stretch'}
			justifyContent='space-evenly'
			width='96%'
			height='100%'
			className='lectures-container'
			borderRadius={'20px'}
			gap>

			<Box

				sx={{
					padding: '5px',
					boxSizing: 'border-box',
					borderRadius: '10px'
				}}
				display={'flex'}
				flexDirection={'column'}
				width={'40%'}
				className='lectures-list-wrapper'
			>
				<LectureListContainer
					setLectureData={setLectureData}
					batch={batch} />
			</Box>

			<Box
				display={'flex'}
				flexDirection={'column'}
				width={'60%'}
				justifyContent={'flex-start'}
				alignItems={'center'}
				sx={{
					padding: '5px 0px',
					boxSizing: 'border-box',
					borderRadius: '10px',
				}}
				className="videoplayer-wrapper"
			>

				<Box
					display={'flex'}
					flexDirection={'column'}
					justifyContent={'space-evenly'}
					alignItems={'center'}
					width={'100%'}
					position={'sticky'}
					top={'100px'}
					sx={{
						boxSizing: 'border-box',
						borderRadius: '10px',
					}}
					className="videoplayer-container"
				>

					{
						lectureData
						&&
						<LectureItem
							lectureData={lectureData} />
					}

					{
						null === lectureData
							||
							'' === lectureData.lectureLink
							||
							lectureData.lectureLink.includes('zoom')
							?
							<></>
							:
							(
								lectureData.lectureLink.includes('youtube')
								// ||
								// lectureData.lectureLink.includes('zoom')
							)
								?
								<YouTubeLecturePlayer
									lectureLink={lectureData.lectureLink} />
								:
								<LectureVideoPlayer
									lectureLink={lectureData.lectureLink} />

					}
				</Box>
			</Box>
		</Box>
	)
}

export default LecturesContainer