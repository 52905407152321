import React, { useEffect, useState } from 'react'

import './DropdownMenu.css'

import { Avatar, Box, Divider, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import { useUser } from '../../UserContext';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';


const navItems = [
    {
        title: "Home",
        icon: <HomeRoundedIcon />,
        to: '/',
    },
    {
        title: "Courses",
        icon: <LibraryBooksRoundedIcon />,
        to: '/courses',
    },
    // {
    //     title: "Contact Us",
    //     icon: <ConnectWithoutContactRoundedIcon />,
    //     to: '/',
    // },
    {
        title: "About",
        icon: <InfoRoundedIcon />,
        to: '/about-us',
    },
];

const menuItems = [
    {
        title: "Dashboard",
        icon: <DashboardRoundedIcon />,
        to: '/user/dashboard',
    },
    {
        title: "Profile",
        icon: <PersonRoundedIcon />,
        to: '/user/profile',
    },
    {
        title: "My Courses",
        icon: <MenuBookRoundedIcon />,
        to: '/user/enrolled-courses/all-courses',
    }
]

export const DropdownMenu = ({ anchorEl, showDropdown, setShowDropdown }) => {

    const navigate = useNavigate();

    const { user, setUser } = useUser();
    const [userPicture, setUserPicture] = useState('');

    const navigateTo = (navigatePath) => {
        navigate(navigatePath);
    }

    const signOut = () => {

        googleLogout();
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('userdata');
        setUser(null);
        navigate('/');
    };

    useEffect(() => {
        if (null !== user && undefined !== user.avatar_picture_url)
            setUserPicture(user.avatar_picture_url);
        else {
            setUserPicture('');
        }
    }, [user])

    return (
        <Menu
            id='dropdown-menu'
            aria-labelledby='dropdown-menu'
            className='dropdown-menu'
            anchorEl={anchorEl}
            open={showDropdown}
            onClose={() => {
                setShowDropdown(false);
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >

            <MenuItem>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}>
                    <Avatar
                        onClick={() => {
                            navigateTo('/user/dashboard');
                            setShowDropdown();
                        }}
                        src={userPicture} />
                </Box>
            </MenuItem>

            <Divider
                variant='middle'
                sx={{
                    borderColor: '#888 !important',
                    borderWidth: '1px !important'
                }} />

            {
                navItems.map((item, index) => {
                    return (
                        <MenuItem
                            key={index}
                            className='nav-item'
                            onClick={() => {
                                navigateTo(item.to);
                                setShowDropdown();
                            }}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            {item.title}
                        </MenuItem>
                    )
                })
            }

            <Divider
                className='nav-item'
                variant='middle'
                sx={{
                    borderColor: '#888 !important',
                    borderWidth: '1px !important'
                }} />

            <Typography
                variant='subtitle1'
                sx={{
                    color: '#0aa !important',
                    padding: '0px 16px !important',
                    textAlign: 'center '
                }}>
                MV Account
            </Typography>

            {
                menuItems.map((item, index) => {
                    return (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                navigateTo(item.to);
                                setShowDropdown();
                            }}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            {item.title}
                        </MenuItem>
                    )
                })
            }

            <Divider
                variant='middle'
                sx={{
                    borderColor: '#888 !important',
                    borderWidth: '1px !important'
                }} />

            <MenuItem onClick={() => {
                signOut();
                setShowDropdown()
            }}>

                <ListItemIcon>
                    <LogoutRoundedIcon />
                </ListItemIcon>
                Signout
            </MenuItem>
        </Menu >
    )
}
