import React, { createContext, useContext, useEffect, useState } from 'react'

const UserContext = createContext();

export const UserProvider = ({ children }) => {

	const [user, setUser] = useState(() => {
		const savedUser = sessionStorage.getItem('user');
		return (savedUser ? JSON.parse(savedUser) : null);
	});

	useEffect(() => {
		if (user) {
			sessionStorage.setItem("user", JSON.stringify(user));
		} else {
			sessionStorage.removeItem("user");
		}
	}, [user]);

	return (
		<UserContext.Provider value={{ user, setUser }}>
			{children}
		</UserContext.Provider>
	)
}

export const useUser = () => {
	const context = useContext(UserContext);

	if (!context) {
		throw new Error("useUsert must be used within a UserProvider");
	}

	return (context);
}
