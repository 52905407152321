import React from 'react'

import './CourseBatch.css';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import LecturesContainer from './Components/LectureContainer/LecturesContainer';
import { useUser } from '../../../../UserContext';
import CountdownTimer from '../../../../Components/CountdownTimer/CountdownTimer';
import { handlePayment } from '../../../../Function/HandlePayment';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';

const CourseBatch = ({ batch }) => {

	const { user, setUser } = useUser();

	return (
		<Box
			className='courses-batch-wrapper'>
			<Box
				display='flex'
				flexDirection='column'
				alignItems='center'
				justifyContent='space-evenly'
				width='99%'
				height='100%'
				className='courses-batch-container header-padding-block'
				borderRadius={'20px'}
				zIndex={2}>

				<Box
					display='flex'
					flexDirection='column'
					alignItems='center'
					justifyContent='space-evenly'
					width='100%'
					height='100%'
					padding={'2px 0px'}
					className='courses-batch-header-container'
					borderTop='2px solid #777'
					borderBottom='2px solid #777'
				>
					<Stack>
						<Typography
							sx={{
								color: '#fff',
								filter: 'drop-shadow(2px 2px 2px #000)',
								textAlign: 'center'
							}}
							variant='h4'>
							{batch.name}
						</Typography>

						<Typography
							sx={{
								color: '#fff',
								filter: 'drop-shadow(2px 2px 2px #000)',
								textAlign: 'center'
							}}
							variant='h6'>
							{batch.batch}
						</Typography>

						{
							(
								user[batch.batch].paidOneTime
								||
								(user[batch.batch].paidInstallment01 && user[batch.batch].paidInstallment02)
							)
							&&
							< Typography
								sx={{
									color: '#fff',
									filter: 'drop-shadow(2px 2px 2px #000)',
									textAlign: 'center'
								}}
								variant='h6'>
								You Have Full Access
							</Typography>

						}

						{
							(
								user[batch.batch].paidInstallment01
								&&
								!user[batch.batch].paidInstallment02
							)
							&&
							<Stack
								display={'flex'}
								width={'100%'}
								flexDirection={'column'}
								alignItems={'center'}
								justifyContent={'center'}
								gap>

								{
									(0 < new Date(user[batch.batch].installment02_deadline) - new Date())
									&&
									<Stack
										display={'flex'}
										width={'100%'}
										flexDirection={'column'}
										alignItems={'center'}
										justifyContent={'center'}
										gap>

										<Typography
											variant='h6'
											color={'#ff0'}>
											Pay Installment 02 In
										</Typography>

										<CountdownTimer
											variant='h6'
											toDateTime={new Date(user[batch.batch].installment02_deadline)} />

									</Stack>
								}

								{
									(0 > new Date(user[batch.batch].installment02_deadline) - new Date())
									&&
									<Stack
										display={'flex'}
										width={'100%'}
										flexDirection={'column'}
										alignItems={'center'}
										justifyContent={'center'}
										gap>

										<Typography
											variant='h6'
											color={'#f00'}>
											Pay Installment 02 In Extended
										</Typography>

										<CountdownTimer
											variant='h6'
											toDateTime={new Date(user[batch.batch].installment02_extended_deadline)} />

									</Stack>
								}

								<Stack
									gap={'20px'}
									flexDirection={'row'}
									alignItems={'center'}
									justifyContent={'space-evenly'}>

									<IconButton
										className='card-online-button'
										onClick={() => {
											if (null === sessionStorage.getItem('user')) {
												const button = document.getElementById('signup_button')
												button.classList.add('navbar_button_highlight')
											} else {
												handlePayment(batch.second_installment,
													false,
													false,
													user,
													setUser,
													batch);
											}
										}}
									>
										<Stack
											gap={'5px'}
											flexDirection={'row'}
											alignItems={'center'}
											justifyContent={'center'}
										>
											<HowToRegOutlinedIcon />
											₹{batch.second_installment}/-
										</Stack>
									</IconButton>
								</Stack>
							</Stack>
						}
					</Stack>

				</Box>

				<LecturesContainer
					batch={batch} />

			</Box>
		</Box >
	)
}

export default CourseBatch