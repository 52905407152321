import React, { useEffect, useState } from 'react'

import './CourseCard.css';
import { Alert, Box, Card, CardContent, CardMedia, Divider, IconButton, Snackbar, Stack, Typography } from '@mui/material';
import { useUser } from '../../../../../../UserContext';


import CountdownTimer from '../../../../../../Components/CountdownTimer/CountdownTimer';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import PersonAddDisabledRoundedIcon from '@mui/icons-material/PersonAddDisabledRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import { handlePayment } from '../../../../../../Function/HandlePayment';

const days = [
	'Mon',
	'Tue',
	'Wed',
	'Thur',
	'Fri',
	'Sat',
	'Sun'
];

function getDaysName(firstDay, nrDays) {
	var dayNames = []
	for (var le = firstDay; le <= (nrDays - 1 + firstDay); ++le) {

		if (firstDay !== le)
			dayNames += " - ";

		dayNames += days[le];
	}
	return (dayNames);
}

function getMinutes(date) {
	if (date.getMinutes() > 12) {

		return (date.getMinutes());
	}
	else {
		return ("0" + date.getMinutes());
	}
}

function getHour(date) {
	if (date.getHours() > 12) {

		return ((date.getHours() % 12) + ":" + getMinutes(date) + " PM");
	}
	else {
		return (date.getHours() + ":0" + getMinutes(date) + " AM");
	}
}

export const CourseCard = ({ courseData }) => {


	const [element, setElem] = useState();
	// const { e_disp } = useState(elem[0].style.display);
	const { user, setUser } = useUser();
	const [isPaymentWindowOpen, setIsPaymentWindowOpen] = useState(false);

	const firstDay = (new Date(courseData.startDate).getDay() === 0 ? 6 : new Date(courseData.startDate).getDay() - 1);
	const dayNames = getDaysName(firstDay, courseData.nrDays);

	const [open, setOpen] = useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	function checkUserAdmission() {
		return new Promise((resolve) => {
			resolve(undefined === user.classroom_online_courses_enrolled
				||
				-1 === user.classroom_online_courses_enrolled.indexOf(courseData.batch)
			)
		})
	}

	const startLoading = () => {
		setElem(document.getElementsByClassName('razorpay-container'));
		// console.log('11')
		// console.log(element[0].style.display);
	}

	const initiatePayment = (fees, oneTimePayment, firstPayment, user, setUser, courseData) => {
		console.log(true)
		handlePayment(fees, oneTimePayment, firstPayment, user, setUser, courseData)
		setIsPaymentWindowOpen(false);
		console.log(false)
	}

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://checkout.razorpay.com/v1/checkout.js';
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);


	return (
		<div
			className='course-card-container'>
			<Card
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					width: '100% !important',
					justifyContent: 'center'
				}}
				className='course-card'>
				{
					"" !== courseData.videoLink
					&&
					<CardMedia
						component="video"
						controls
						autoPlay={true}
						muted
						disablePictureInPicture={true}
						disableRemotePlayback={true}
						controlsList='nodownload noplaybackrate'
						playsInline
						loop
						tabIndex="-1" // Adding tabindex
						role="application" // Adding role
						src={courseData.videoLink}
						alt="Course Poster"
						style={{
							width: '100%',
							aspectRatio: '16/9 !important',
							borderRadius: 'inherit !important'
						}}
					/>
				}
				<div
					className='card-content'
					style={{
						width: '100%',
					}}>
					<Typography
						variant='h4'
						fontFamily={"inherit"}
						sx={{
							color: '#ff0',
							fontWeight: '400'
						}}>
						₹{courseData.fees * (100 - courseData.discount) / 100}/-
					</Typography>
					<Typography
						variant='h5'
						fontFamily={"inherit"}
						sx={{
							color: '#aaa',
							fontWeight: '100',
							textDecoration: 'line-through'
						}}
					>
						₹{courseData.fees}/-
					</Typography><Typography
						variant='h4'
						fontFamily={"inherit"}
						sx={{
							color: '#FFA500',
							filter: 'drop-shadow(0px 0px 4px #FFAF0077)'
						}}
					>
						{courseData.discount}% OFF
					</Typography>
				</div>

				<CardContent
					sx={{
						padding: '0px !important',
						width: '100%',
					}}>

					<Stack
						direction={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}>
						<Stack
							direction={'row'}
							alignItems={'center'}>
							<AccessTimeRoundedIcon
								sx={{
									color: '#fff !important'
								}}
							/>
							<Typography sx={{ margin: '5px', color: '#fff !important' }} variant='h5' >Time</Typography>
						</Stack>
						<Stack
							direction={'row'}
							alignItems={'center'}>
							<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >{getHour(new Date(courseData.fromTime))}</Typography>
							<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >to</Typography>
							<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >{getHour(new Date(courseData.toTime))}</Typography>
						</Stack>
					</Stack>

					<Stack
						direction={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}>

						<Stack
							direction={'row'}
							alignItems={'center'}>
							<TodayRoundedIcon
								sx={{
									color: '#fff !important'
								}}
							/>
							<Typography sx={{ margin: '5px', color: '#fff !important' }} variant='h5' >Days</Typography>
						</Stack>
						<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >{dayNames}</Typography>
					</Stack>

					<Stack
						direction={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}>
						<Stack
							direction={'row'}
							alignItems={'center'}>
							<DateRangeRoundedIcon
								sx={{
									color: '#fff !important'
								}}
							/>
							<Typography sx={{ margin: '5px', color: '#fff !important' }} variant='h5' >Starts</Typography>
						</Stack>
						<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >{new Date(courseData.startDate).toDateString()}</Typography>
					</Stack>

					<Stack
						direction={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}>
						<Stack
							direction={'row'}
							alignItems={'center'}>
							<CalendarMonthRoundedIcon
								sx={{
									color: '#fff !important'
								}}
							/>
							<Typography sx={{ margin: '5px', color: '#fff !important' }} variant='h5' >Duration</Typography>
						</Stack>
						<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >{courseData.nrMonths} Months</Typography>
					</Stack>

					<Stack
						direction={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}>

						<Stack
							direction={'row'}
							alignItems={'center'}>
							<LanguageRoundedIcon
								sx={{
									color: '#fff !important'
								}}
							/>
							<Typography sx={{ margin: '5px', color: '#fff !important' }} variant='h5' >Language</Typography>
						</Stack>
						<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >Marathi</Typography>
					</Stack>

					<Stack
						direction={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}>

						<Stack
							direction={'row'}
							alignItems={'center'}>
							<VideoCameraFrontOutlinedIcon
								sx={{
									color: '#fff !important'
								}}
							/>
							<Typography sx={{ margin: '5px', color: '#fff !important' }} variant='h5' >Mode</Typography>
						</Stack>
						<Typography sx={{ margin: '5px', color: '#aaa !important' }} variant='h5' >ZOOM(online)</Typography>
					</Stack>

				</CardContent>

				<Box
					display={'flex'}
					flexDirection={'row'}
					justifyContent={'center'}
					alignItems={'flex-start'}
					gap>
					<Typography
						variant='h5'
						sx={{
							color: '#00ff00',
							width: '100%',
						}}>
						Admission Open
					</Typography>
				</Box>

				<CountdownTimer
					toDateTime={new Date(courseData.admissionTillDate)}
				/>

				{
					(
						courseData.courseIsAdmissionsOpen
						// &&
						// undefined === user[courseData.batch]
					)
						?
						<div
							className='card-content'
							style={{
								width: '100%',
							}}>

							<Stack
								display={'flex'}
								flexDirection={'row'}
								justifyContent={'space-evenly'}
								alignItems={'center'}
								width={'100%'}>

								<Stack
									gap={'2px'}
									justifyContent={'space-evenly'}
									alignItems={'center'}>

									<Typography
										variant='subtitle1'
										color={"#fff"}>
										Pay One Time
									</Typography>

									<IconButton
										className='card-online-button'
										onClick={() => {
											if (null === sessionStorage.getItem('user')) {
												const button = document.getElementById('signup_button')
												button.classList.add('navbar_button_highlight')
											} else {
												startLoading();
												checkUserAdmission()
													.then((value) => {
														if (value)
															initiatePayment(
																courseData.fees * (100 - courseData.discount) / 100,
																true,
																false,
																user,
																setUser,
																courseData);
														else {
															setOpen(true);
														}
													})
											}
										}}
									>
										<Stack
											gap={'5px'}
											flexDirection={'row'}
											alignItems={'center'}
											justifyContent={'center'}
										>
											<HowToRegOutlinedIcon />
											₹{courseData.fees * (100 - courseData.discount) / 100}/-
										</Stack>
									</IconButton>
								</Stack>

								<Divider
									orientation="vertical" />

								<Stack
									gap={'2px'}
									alignItems={'center'}
									justifyContent={'space-evenly'}>

									<Typography
										variant='subtitle1'
										color={"#fff"}>
										Pay Installment
									</Typography>

									<Stack
										gap={'20px'}
										flexDirection={'row'}
										alignItems={'center'}
										justifyContent={'space-evenly'}>

										<IconButton
											className='card-online-button'
											onClick={() => {
												if (null === sessionStorage.getItem('user')) {
													const button = document.getElementById('signup_button')
													button.classList.add('navbar_button_highlight')
												} else {

													checkUserAdmission()
														.then((value) => {
															if (value)
																initiatePayment(courseData.first_installment,
																	false,
																	true,
																	user,
																	setUser,
																	courseData);
															else {
																setOpen(true);
															}
														})
												}
											}}
										>
											<Stack
												gap={'5px'}
												flexDirection={'row'}
												alignItems={'center'}
												justifyContent={'center'}
											>
												<HowToRegOutlinedIcon />
												₹{courseData.first_installment}/-
											</Stack>
										</IconButton>
									</Stack>
								</Stack>
							</Stack>
						</div>
						:
						<span
							className={courseData.courseIsAdmissionsOpen ? 'admission_open admission_line' : 'admission_closed admission_line'} >
							{courseData.courseIsAdmissionsOpen ? <HowToRegRoundedIcon /> : <PersonAddDisabledRoundedIcon />}
							{courseData.courseIsAdmissionsOpen ? 'Admission Open' : 'Admission Closed'}
						</span>
				}
			</Card >

			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				open={open}
				autoHideDuration={3000}
				onClose={handleClose}>

				<Alert
					onClose={handleClose}
					severity="success"
					variant="filled"
					sx={{ width: '100%' }}
				>
					Your admission is already Done
				</Alert>
			</Snackbar>
		</div >
	)

}

