import React, { useState } from 'react'

import './CourseContent.css';

import { ContentPart } from './Components/ContentParts/ContentPart';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { IconButton, Stack, Typography } from '@mui/material';

export const CourseContent = ({ courseContent, contentDownloadLink, id }) => {

    const [openPart, setOpenPart] = useState(-1);

    if (!courseContent) {
        return (
            <div>
                Loading...
            </div>
        )
    }

    return (
        <div
            id={id}
            className='course-content'>

            <div
                className='course-content-container'>

                <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    width={'100%'}
                    justifyContent={'space-between'}
                >
                    <h1
                        className='courseContent_Title'>
                        Content
                    </h1>

                    <IconButton
                        sx={{
                            color: '#0ff !important',
                        }}
                        onClick={() => {
                            window.open(contentDownloadLink, "_blank");
                        }}>
                        <DownloadForOfflineRoundedIcon
                            sx={{
                                fontSize: "2rem !important"
                            }} />
                        <Typography
                            variant='h6'>
                            Download
                        </Typography>
                    </IconButton>
                </Stack>

                <div
                    className='content-parts-container'>
                    {
                        courseContent.map((partContent, index) => {
                            return (
                                <ContentPart
                                    isEven={index % 2 === 0}
                                    _key={index}
                                    contentPart={partContent}
                                    isOpen={(openPart === index)}
                                    setOpen={() => {
                                        if (-1 === openPart || index !== openPart) {
                                            setOpenPart(index);
                                        } else {
                                            setOpenPart(-1);
                                        }
                                    }}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
