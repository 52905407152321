import React, { useRef } from 'react'

import './LecturesList.css';

import { Box, Typography } from '@mui/material'
import LectureItem from './LectureItem';

const LecturesList = ({ title, lectures, setLectureData, lectureOrderInvert = false, isRecorded = false }) => {

	const lectureNo = useRef(0);

	if (undefined === lectures || 0 === lectures.length) {
		return (
			<></>
		)
	}

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='flex-start'
			justifyContent='space-evenly'
			width='100%'
			height='100%'
			padding={'2px 0px'}
			className='lectures-list'
			gap>

			<Typography
				variant='h5'>
				{title}
			</Typography>

			<Box
				display='flex'
				flexDirection={lectureOrderInvert ? 'column-reverse' : 'column'}
				alignItems='flex-start'
				justifyContent='space-evenly'
				width='100%'
				height='100%'
				gap>

				{
					lectures.map((lecture, index) => {

						if (!lecture.lectureIsHoliday)
							lectureNo.current = lectureNo.current + 1

						if (index === 0)
							lectureNo.current = 1;

						return (
							<LectureItem
								isRecorded={isRecorded}
								key={index}
								toShowTimer={'Batch Lectures' === title}
								// setLectureLink={setLectureLink}
								lectureNo={lectureNo.current}
								lecture={lecture}
								setLectureData={setLectureData} />
						)
					})
				}

			</Box>
		</Box >
	)
}

export default LecturesList