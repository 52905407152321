import React, { useEffect, useState } from 'react'

import './CourseCardItem.css';

import { Box, Card, CardContent, CardMedia, Divider, Slider, Stack, Typography } from '@mui/material'
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../../../Components/Common/firebase';
import { NavLink, useNavigate } from 'react-router-dom';

function getLecturesCount(course) {
	var count = 0;

	if (undefined !== course.openLectures) {
		count += course.openLectures.length
	}

	if (undefined !== course.closedLectures) {
		count += course.closedLectures.length
	}

	if (undefined !== course.recordedLectures) {
		count += course.recordedLectures.length
	}

	return (count);
}

function getLiveLectureCount(course) {
	var count = 0;

	if (undefined !== course.openLectures) {
		count += course.openLectures.length
	}

	if (undefined !== course.closedLectures) {
		count += course.closedLectures.length
	}
	return (count);
}

function getRecordedLectureCount(course) {
	var count = 0;

	if (undefined !== course.recordedLectures) {
		count += course.recordedLectures.length
	}

	return (count);
}

const CourseCardItem = ({ courseData, index }) => {

	const [posterImageLink, setPosterImageLink] = useState('');
	const navigate = useNavigate();

	useEffect(() => {

		getDownloadURL(ref(storage, 'coursePosters/' + courseData.posterWideName))
			.then((link) => {
				setPosterImageLink(link);
			})

	}, [courseData.posterWideName]);

	const handleGoBack = (e) => {
		e.preventDefault(); // Prevent default navigation
		navigate('../../' + courseData.batch.split('_')[0]);
	}

	return (
		<NavLink
			key={index}
			onClick={handleGoBack}
			style={{
				textDecoration: 'none',
				cursor: 'pointer'
			}}>
			<Card
				sx={{
					display: 'flex',
					width: '250px',
					minHeight: '350px',
					maxHeight: 'max-content',
					padding: '10px',
					borderRadius: '10px !important',
				}}
				className='user-enrolled-course-card-item'>

				<Box
					display={'flex'}
					flexDirection={'column'}
					alignItems='center'
					width='100%'>

					<CardMedia
						component={'img'}
						src={posterImageLink}
						alt=''
						sx={{
							width: '100%',
							borderRadius: '10px'
						}}
					/>

					<CardContent
						sx={{
							padding: '0px !important',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-evenly',
							alignItems: 'center',
							height: '100%'
						}}>

						<Typography
							sx={{
								color: '#fff !important'
							}}
							variant='h5'>
							{courseData.key} | {courseData.batch}
						</Typography>

						<Typography
							sx={{
								textAlign: 'center',
								color: '#fff !important'
							}}
							variant='h5'>
							{courseData.name}
						</Typography>

						<Stack
							justifyContent={'center'}
							alignItems={'center'}
							direction={'row'}
							divider={<Divider orientation="vertical" flexItem />}
							spacing={2}>
							<Stack>
								<Typography
									sx={{
										lineHeight: '1.2 !important',
										textAlign: 'center',
										color: '#fff !important'
									}}
									variant='h5'>
									{getLecturesCount(courseData)}
								</Typography>
								<Typography
									sx={{
										lineHeight: '1.2 !important',
										textAlign: 'center',
										color: '#fff !important'
									}}
									variant='subtitle2'>
									Total Lectures
								</Typography>
							</Stack>
							<Stack>
								<Typography
									sx={{
										lineHeight: '1.2 !important',
										textAlign: 'center',
										color: '#fff !important'
									}}
									variant='h5'>
									{getLiveLectureCount(courseData)}
								</Typography>
								<Typography
									sx={{
										lineHeight: '1.2 !important',
										textAlign: 'center',
										color: '#fff !important'
									}}
									variant='subtitle2'>
									Live Lectures
								</Typography>
							</Stack>
							<Stack>
								<Typography
									sx={{
										lineHeight: '1.2 !important',
										textAlign: 'center',
										color: '#fff !important'
									}}
									variant='h5'>
									{getRecordedLectureCount(courseData)}
								</Typography>
								<Typography
									sx={{
										lineHeight: '1.2 !important',
										textAlign: 'center',
										color: '#fff !important'
									}}
									variant='subtitle2'>
									Recorded Lectures
								</Typography>
							</Stack>
						</Stack>

						<Box sx={{ width: '100%' }}>
							<Slider
								variant='determinant'
								value={20} />
						</Box>


					</CardContent>
				</Box>
			</Card>
		</NavLink>
	)
}

export default CourseCardItem