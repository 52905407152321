import React, { useState } from 'react'

import './EditProfile.css';
import { firestore } from './../../../../../../Components/Common/firebase';

import { doc, setDoc } from 'firebase/firestore';
import { Alert, Avatar, Box, Button, Divider, Snackbar, Stack, Typography } from '@mui/material';

import CustomTextField from '../CustomTextField/CustomTextField';
import { useUser } from '../../../../../../UserContext';

const EditProfile = () => {

	//console.log(user);

	const { user } = useUser();
	const [open, setOpen] = useState(false);
	const [contactNo, setContactNo] = useState(user.contactno || 0);

	const handleContactNoChange = (event) => {
		const regex = /^[0-9]+$/;
		if (regex.test(event.target.value) &&
			(0 <= event.target.value.length) &&
			(10 >= event.target.value.length)) {
			setContactNo(event.target.value)
		}
		else if (0 === event.target.value.length) {
			setContactNo(event.target.value)
		}
	}

	const handleClick = () => {

		user.contactno = contactNo;

		setDoc(doc(firestore, 'Users/' + user.uid), user, { merge: true })
			.then(() => {
				setOpen(true);
			})
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='space-evenly'
			width='100%'
			height={'100%'}
			className='edit-profile-wrapper'>

			<Box
				display='flex'
				flexDirection='column'
				alignItems='flex-start'
				alignContent={'stretch'}
				justifyContent='space-evenly'
				width='100%'
				height={'100%'}
				className='edit-profile-container'>

				<Stack
					className='profile-avatar-group'
					direction={'row'}
					width={'100%'}
					boxSizing={'border-box'}
					padding={'10px 20px'}
					gap>
					<Avatar
						sx={{
							width: "140px !important",
							height: "140px !important",
							border: '2px solid rgb(77, 172, 255)'
						}}
						src={user.avatar_picture_url} />

					<Stack
						direction={'column'}
						gap
						height={'100%'}>
						<Typography
							color={"#fff !important"}
							variant='h4'>
							{user.user_name}
						</Typography>
						<Typography
							color={"#999 !important"}
							variant='h5'>
							{user.designation}
						</Typography>
					</Stack>

				</Stack>

				<Divider
					sx={{
						borderColor: '#888',
						borderWidth: '1px',
						width: '100%',
						alignSelf: 'stretch'
					}} />


				<Box
					display='flex'
					flexDirection='column'
					alignItems='flex-start'
					justifyContent='space-evenly'
					width='100%'
					padding={'10px 20px'}
					boxSizing={'border-box'}
					gap={'10px !important'}>

					<Box
						display='flex'
						flexDirection='column'
						alignItems='flex-start'
						justifyContent='space-evenly'
						width='100%'
						boxSizing={'border-box'}
						gap
						className='edit-user-info-container'>

						<Stack
							direction={'column'}
							height={'100%'}>
							<Typography
								color={"#fff !important"}
								variant='h5'>
								Personal Details
							</Typography>
							<Typography
								color={"#aaa !important"}
								variant='h6'>
								Edit your personal details
							</Typography>
						</Stack>

						<Box
							display='flex'
							flexDirection='row'
							flexWrap={'wrap'}
							alignItems='flex-start'
							justifyContent='space-between'
							width='100%'
							boxSizing={'border-box'}>

							<CustomTextField
								width="45%"
								disabled
								defaultValue={user.first_name}
								id="standard-basic"
								label="First Name"
								variant="standard"
							/>

							<CustomTextField
								width="45%"
								disabled
								defaultValue={user.last_name}
								id="standard-basic"
								label="Last Name"
								variant="standard"
							/>

							<CustomTextField
								width="45%"
								disabled
								defaultValue={user.user_name}
								id="standard-basic"
								label="User Name"
								variant="standard"
							/>

							<CustomTextField
								width="45%"
								disabled
								defaultValue={user.mailid}
								id="standard-basic"
								label="Mail ID"
								variant="standard"
							/>

							<CustomTextField
								width="45%"
								type='text'
								defaultValue={contactNo}
								value={contactNo}
								onChange={handleContactNoChange}
								id="standard-basic"
								label="Contact No"
								variant="standard"
							/>
						</Box>
					</Box>

					<Button
						onClick={handleClick}
						variant='contained'>
						Update Profile
					</Button>

				</Box>
			</Box>

			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				open={open} autoHideDuration={3000} onClose={handleClose}
				sx={{ zIndex: 1300 }} // Adjust the zIndex as needed
			>
				<Alert
					onClose={handleClose}
					severity="success"
					variant="filled"
					sx={{ width: '100%' }}
				>
					Profile Saved Successful
				</Alert>
			</Snackbar>
		</Box >
	)
}

export default EditProfile