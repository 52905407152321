import React from 'react'

import './EnrolledCourses.css';

import { Box, Divider, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';

import EnrolledCoursesNavBar from './EnrolledCoursesNavBar';

const EnrolledCourses = () => {

	return (
		<Box
			display='flex'
			flexDirection='column'
			alignItems='flex-start'
			justifyContent='space-evenly'
			width='100%'
			height={'100%'}
			zIndex={'5'}
			className='enrolled-courses-wrapper'>

			<Typography
				variant='h4'
				sx={{
					padding: '10px'
				}}>
				Enrolled Courses
			</Typography>

			<Divider
				sx={{
					borderColor: '#888',
					borderWidth: '1px',
					width: '100%'
				}} />

			<Box
				display='flex'
				flexDirection='column'
				alignItems='center'
				justifyContent='space-evenly'
				width='100%'
				padding={'10px 0px'}
				className='enrolled-courses-container'
				gap>

				<EnrolledCoursesNavBar />

				{/* <AppBar
					position='static'>
					<Tabs>
						<Tab
							label='One' />
						<Tab
							label='One' />
						<Tab
							label='One' />
						<Tab
							label='One' />
					</Tabs>
				</AppBar> */}

				<Outlet />

			</Box>
		</Box>
	)
}

export default EnrolledCourses